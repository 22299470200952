import { Page } from "@dangerfarms/website-core";
import { Box, Button, ContentBlock, Stack, Text } from "@dangerfarms/ui-system";
import { Link } from "gatsby";
import React from "react";

const ThanksPage = () => {
  return (
    <Page noindex title="Thanks">
      <Box paddingX="gutter" paddingY={8}>
        <ContentBlock>
          <Stack>
            <Stack space={0.5}>
              <Text as="h1" variant="heading">
                Thanks for your enquiry
              </Text>
              <Text maxWidth="30em" variant="lede">
                We&apos;ll be in touch soon.
              </Text>
            </Stack>
            <Button as={Link} to="/">
              Take me home
            </Button>
          </Stack>
        </ContentBlock>
      </Box>
    </Page>
  );
};

export default ThanksPage;
